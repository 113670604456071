import { ApolloError } from "@apollo/client";
import { triggerErrorToast } from "components/shared";

export const onError = (error: ApolloError) => console.error("Error: ", error);

export const errorToast = (fetchEntity: string, error?: ApolloError | string) =>
  triggerErrorToast({
    message: "Looks like something went wrong.",
    sub: `We weren't able to fetch ${fetchEntity}`,
    log: error,
  });
