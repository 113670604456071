import { Menu, Transition } from "@headlessui/react";
import { resolveStringyFunction } from "@utils/strings";
import clsx from "clsx";
import { Button, ButtonHeight, ThemeT } from "components/shared";
import compact from "lodash/compact";
import { Fragment, ReactNode } from "react";
import { HiDotsHorizontal } from "react-icons/hi";

export type PageHeaderItemProps = {
  label: ReactNode | string;
  icon?: ReactNode;
  onClick: () => void;
};

export type PageHeaderButtonProps = PageHeaderItemProps & {
  theme?: ThemeT;
  height?: ButtonHeight;
  className?: string;
};

export type PageHeaderMenuItemProps = PageHeaderItemProps & {
  className?: string | ((active: boolean) => string);
};

export type PageHeaderButtonGroupProps = {
  buttons?: (PageHeaderButtonProps | undefined)[];
  menuItems?: (PageHeaderMenuItemProps | undefined)[];
  className?: string;
  children?: ReactNode;
};

export const PageHeaderButtonGroup = ({
  className = "flex flex-row items-center justify-end gap-2 w-fit flex-wrap ml-auto",
  buttons,
  menuItems,
  children,
}: PageHeaderButtonGroupProps) => (
  <div className={className}>
    {compact(buttons)?.map((button, i) => (
      <PageHeaderButton key={i} {...button} />
    ))}
    {children}
    {compact(menuItems)?.length > 0 && (
      <Menu as="div" className="relative">
        <Menu.Button
          className={clsx(
            "flex items-center rounded-full p-1.5",
            "text-white bg-gray-600 hover:bg-gray-800",
            "focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          )}
        >
          <span className="sr-only">Open options</span>
          <HiDotsHorizontal className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={clsx(
              "divide-y divide-gray-200 origin-top-right select-none cursor-pointer",
              "absolute right-0 mt-2 w-auto rounded-md shadow-lg py-1 bg-white",
              "ring-1 ring-black ring-opacity-5 focus:outline-none"
            )}
          >
            {compact(menuItems)?.map((menuItem, i) => (
              <PageHeaderMenuItem key={i} {...menuItem} />
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    )}
  </div>
);

const PageHeaderButton = ({
  icon,
  label,
  height,
  theme = "tertiary",
  className = "flex flex-row gap-x-2 flex-nowrap text-gray-600",
  onClick,
}: PageHeaderButtonProps) => (
  <Button onClick={onClick} className={className} theme={theme} height={height}>
    {icon}
    {label}
  </Button>
);

const PageHeaderMenuItem = ({
  icon,
  label,
  className = (active: boolean) =>
    clsx(
      "flex flex-row gap-x-2 justify-start items-center px-4 py-2 text-gray-600 text-sm whitespace-nowrap",
      active ? "bg-gray-50" : ""
    ),
  onClick,
}: PageHeaderMenuItemProps) => (
  <Menu.Item>
    {({ active }) => (
      <div
        className={resolveStringyFunction(className, active)}
        onClick={onClick}
      >
        {icon}
        {label}
      </div>
    )}
  </Menu.Item>
);
