import { ArchiveIcon, BellIcon, CalendarIcon } from "@heroicons/react/outline";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClipboardCopyIcon,
  CogIcon,
  ExclamationCircleIcon,
  HomeIcon,
  LinkIcon,
  MailIcon,
  PrinterIcon,
  SelectorIcon,
  TrashIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { getSize } from "@utils/styleStrings";
import clsx from "clsx";
import {
  AiFillCheckCircle,
  AiFillExperiment,
  AiFillInfoCircle,
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiFillSafetyCertificate,
  AiFillSignature,
  AiOutlineDashboard,
} from "react-icons/ai";
import {
  BiLogoZoom,
  BiSolidEdit,
  BiSolidSave,
  BiVideoRecording,
} from "react-icons/bi";
import {
  BsBuildingGear,
  BsCalendarWeek,
  BsDatabaseFillX,
  BsFilePerson,
} from "react-icons/bs";
import {
  CgMaximize,
  CgMediaLive,
  CgMinimize,
  CgProfile,
  CgSpinnerTwo,
} from "react-icons/cg";
import { CiStickyNote } from "react-icons/ci";
import {
  FaBook,
  FaChalkboard,
  FaChartLine,
  FaClipboardList,
  FaClock,
  FaEyeSlash,
  FaFlag,
  FaGraduationCap,
  FaLock,
  FaMinus,
  FaPlus,
  FaRegBuilding,
  FaRegEye,
  FaRegFolderOpen,
  FaScroll,
  FaSortAmountDown,
  FaUndo,
  FaUserEdit,
  FaUserPlus,
  FaUserTimes,
} from "react-icons/fa";
import {
  FaAnglesLeft,
  FaAnglesRight,
  FaCheckToSlot,
  FaSchool,
} from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { GiMaterialsScience, GiNotebook, GiTriggerHurt } from "react-icons/gi";
import { GoAlert, GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
import { GrAggregate, GrMultiple, GrTest } from "react-icons/gr";
import {
  HiCheck,
  HiOutlineDocumentReport,
  HiOutlineExclamation,
  HiOutlineExternalLink,
  HiOutlineMenuAlt2,
  HiOutlineSearch,
  HiUserGroup,
} from "react-icons/hi";
import { HiArchiveBoxXMark, HiInboxArrowDown } from "react-icons/hi2";
import { ImSpinner8 } from "react-icons/im";
import { IoIosExit, IoIosExpand, IoMdEye, IoMdEyeOff } from "react-icons/io";
import {
  IoArrowUndoCircleOutline,
  IoCheckmarkCircle,
  IoCloseCircle,
  IoDocumentText,
  IoEllipsisHorizontalCircleSharp,
  IoFilterCircleOutline,
  IoSparklesSharp,
  IoTerminalOutline,
} from "react-icons/io5";
import { LiaUserEditSolid } from "react-icons/lia";
import { LuCalendarDays, LuClipboardCheck, LuPalmtree } from "react-icons/lu";
import {
  MdClass,
  MdDoNotDisturbAlt,
  MdEventNote,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatStrikethrough,
  MdGrade,
  MdGroupWork,
  MdInfo,
  MdOutlineAutoGraph,
  MdOutlineClass,
  MdOutlineFingerprint,
  MdOutlineGroupWork,
  MdOutlinePayments,
  MdOutlinePlaylistAddCheckCircle,
  MdPeopleAlt,
  MdPersonRemove,
  MdPlayCircle,
  MdRedo,
  MdSchedule,
  MdScoreboard,
  MdSource,
  MdTimelapse,
  MdUndo,
  MdVpnKey,
} from "react-icons/md";
import {
  PiAirTrafficControl,
  PiFolderOpen,
  PiGearFineBold,
  PiStudentFill,
  PiUserCircleGear,
  PiWarningFill,
} from "react-icons/pi";
import {
  RiContactsBook2Fill,
  RiEditCircleFill,
  RiGroup2Fill,
  RiGroup2Line,
  RiLoader4Fill,
  RiQuoteText,
} from "react-icons/ri";
import {
  TbCalendarPause,
  TbCalendarX,
  TbCertificate,
  TbCopy,
  TbCopyCheckFilled,
  TbFileInfo,
  TbGhost,
  TbKeyboard,
  TbListDetails,
  TbLivePhotoOff,
  TbLockShare,
  TbMath,
  TbRefreshAlert,
  TbUsers,
} from "react-icons/tb";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { MinimalCopyButton } from "./Buttons";

export type IconType = keyof typeof IconMap;

interface Props {
  size?: number;
  color?: string;
  icon?: IconType;
  button?: boolean;
  className?: string;
  onClick?: () => void;
}

export const Icon = ({
  size = 6,
  className,
  icon = "info",
  button = false,
  color = "text-blue-500",
  onClick,
}: Props) => {
  const IconComponent = IconMap[icon];

  return (
    <IconComponent
      className={clsx(
        "flex-shrink-0 group",
        getSize(size),
        color,
        className,
        button && "mr-[6px] ml-[-6px]"
      )}
      aria-hidden="true"
      onClick={() => onClick?.()}
    />
  );
};

export const INTERNAL_USE_ONLY_ICON_LIBRARY = () => {
  const icons = Object.keys(IconMap) as IconType[];
  return (
    <div className="grid grid-cols-5 gap-2 bg-gray-100 p-4 rounded">
      {icons.sort().map((icon) => (
        <div
          key={icon}
          className="flex flex-col items-center bg-white p-1 rounded"
        >
          <Icon icon={icon} size={8} color="text-black" />
          <MinimalCopyButton copyValue={icon} label={icon} />
        </div>
      ))}
    </div>
  );
};

export const IconMap = {
  account: PiFolderOpen,
  add: AiFillPlusCircle,
  addUser: FaUserPlus,
  aggregate: GrAggregate,
  alert: GoAlert,
  analytics: MdOutlineAutoGraph,
  anglesLeft: FaAnglesLeft,
  anglesRight: FaAnglesRight,
  application: LuClipboardCheck,
  archive: ArchiveIcon,
  arrowDown: ArrowDownIcon,
  arrowUp: ArrowUpIcon,
  assessment: GrTest,
  attendance: MdOutlinePlaylistAddCheckCircle,
  blockquote: RiQuoteText,
  bold: MdFormatBold,
  building: FaRegBuilding,
  bulk: GrMultiple,
  calendar: CalendarIcon,
  calendarDays: LuCalendarDays,
  calendarEvents: BsCalendarWeek,
  calendarPause: TbCalendarPause,
  cancelCalendar: TbCalendarX,
  candidate: BsFilePerson,
  check: IoCheckmarkCircle,
  checkCircle: AiFillCheckCircle,
  checkmark: HiCheck,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  circleEdit: RiEditCircleFill,
  circleEllipses: IoEllipsisHorizontalCircleSharp,
  closeSidebar: GoSidebarCollapse,
  cog: CogIcon,
  cohort: MdOutlineGroupWork,
  cohortFill: MdGroupWork,
  controlPanel: PiAirTrafficControl,
  copy: TbCopy,
  copyClipboard: ClipboardCopyIcon,
  copySuccess: TbCopyCheckFilled,
  course: MdOutlineClass,
  dashboard: AiOutlineDashboard,
  database: BsDatabaseFillX,
  details: TbListDetails,
  disabled: MdDoNotDisturbAlt,
  document: IoDocumentText,
  doubleSpinner: CgSpinnerTwo,
  edit: BiSolidEdit,
  editUser: FaUserEdit,
  ela: GiNotebook,
  eld: FaBook,
  engagement: RiGroup2Line,
  engagementFill: RiGroup2Fill,
  error: XCircleIcon,
  exclamationCircle: ExclamationCircleIcon,
  exit: IoIosExit,
  expand: IoIosExpand,
  externalLink: HiOutlineExternalLink,
  eyeOff: IoMdEyeOff,
  eyeOn: IoMdEye,
  filters: IoFilterCircleOutline,
  fingerprint: MdOutlineFingerprint,
  flag: FaFlag,
  general: FaChalkboard,
  ghost: TbGhost,
  goLive: CgMediaLive,
  grade: MdGrade,
  gradebook: RiContactsBook2Fill,
  group: UserGroupIcon,
  home: HomeIcon,
  info: MdInfo,
  infoCircle: AiFillInfoCircle,
  infoFile: TbFileInfo,
  italic: MdFormatItalic,
  job: FaClipboardList,
  key: MdVpnKey,
  keyboard: TbKeyboard,
  link: LinkIcon,
  loading: ImSpinner8,
  loadingIcon: RiLoader4Fill,
  lock: FaLock,
  mail: MailIcon,
  managing: BsBuildingGear,
  math: TbMath,
  maximize: CgMaximize,
  menu: HiOutlineMenuAlt2,
  microsoftTeams: UsersIcon,
  minimize: CgMinimize,
  minus: FaMinus,
  new: IoSparklesSharp,
  noShow: FaEyeSlash,
  notArchived: HiArchiveBoxXMark,
  note: CiStickyNote,
  notification: BellIcon,
  notLive: TbLivePhotoOff,
  openJobs: FaRegFolderOpen,
  openSidebar: GoSidebarExpand,
  orderedList: MdFormatListNumbered,
  organization: FaRegBuilding,
  palmTree: LuPalmtree,
  payment: MdOutlinePayments,
  pending: FaClock,
  play: MdPlayCircle,
  plus: FaPlus,
  printer: PrinterIcon,
  productType: FaSchool,
  profile: CgProfile,
  qaApp: PiUserCircleGear,
  redoEdit: MdRedo,
  refreshAlert: TbRefreshAlert,
  remove: IoCloseCircle,
  removePerson: MdPersonRemove,
  removeUser: FaUserTimes,
  report: HiOutlineDocumentReport,
  requirements: TbCertificate,
  save: BiSolidSave,
  sched: MdSchedule,
  science: GiMaterialsScience,
  scoreBoard: MdScoreboard,
  search: HiOutlineSearch,
  searchAnalytics: FaChartLine,
  select: SelectorIcon,
  session: MdEventNote,
  sessionReport: AiFillSafetyCertificate,
  settings: PiGearFineBold,
  shareLock: TbLockShare,
  signature: AiFillSignature,
  slot: FaCheckToSlot,
  socialStudies: FaScroll,
  sort: FaSortAmountDown,
  sortDown: TiArrowSortedDown,
  sortUp: TiArrowSortedUp,
  source: MdSource,
  staffing: LiaUserEditSolid,
  strikethrough: MdFormatStrikethrough,
  student: PiStudentFill,
  subject: MdClass,
  submit: HiInboxArrowDown,
  substitute: MdPeopleAlt,
  subtract: AiFillMinusCircle,
  teacher: FaGraduationCap,
  terminal: IoTerminalOutline,
  testing: AiFillExperiment,
  timeLapse: MdTimelapse,
  trash: TrashIcon,
  triangleExclamation: HiOutlineExclamation,
  trigger: GiTriggerHurt,
  undo: IoArrowUndoCircleOutline,
  undoCircle: FaUndo,
  undoEdit: MdUndo,
  unorderedList: MdFormatListBulleted,
  user: FiUser,
  userGroup: HiUserGroup,
  users: TbUsers,
  video: BiVideoRecording,
  videoProvider: BiLogoZoom,
  visitor: FaRegEye,
  warning: PiWarningFill,
  zoom: VideoCameraIcon,
};
