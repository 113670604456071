import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import {
  PropsWithChildren,
  ReactNode,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";
import { EmptyStateContainer } from "./EmptyStateContainer";
import { Icon } from "./Icon";

type Props = {
  title: string;
  children?: ReactNode;
  isOpen: boolean;
  width?: "narrow" | "wide";
  onClose: () => void;
  className?: string;
};

export const DetailsAside = ({
  title,
  children,
  isOpen,
  onClose,
  className,
}: Props) =>
  !isOpen ? null : (
    <aside
      className={clsx(
        "hidden xl:flex flex-grow flex-col",
        "rounded-lg overflow-hidden",
        "gap-y-3 shadow-sm bg-white",
        "h-fit shrink-0",
        "min-w-[400px] max-w-[33%] ml-2",
        "border border-gray-200",
        className
      )}
    >
      <div
        className={clsx(
          "flex h-10 border-b justify-between items-center border-gray-200 px-4 bg-gray-50",
          "group cursor-pointer"
        )}
        onClick={onClose}
      >
        <h2 className="text-base font-bold text-gray-800">
          <span className="sr-only">Details for </span>
          {title}
        </h2>

        <Icon
          icon="closeSidebar"
          color="text-gray-800"
          className="group-hover:text-blue-600 group-active:text-blue-800"
        />
      </div>

      <div
        className={clsx(
          "flex flex-col p-4 pt-0 h-fit w-full gap-y-3 overflow-y-auto",
          getScrollbarStyle("gray")
        )}
      >
        {children}
      </div>
    </aside>
  );

type DetailSectionProps = {
  title: string;
  children: ReactNode;
  actions?: ReactNode;
  className?: string;
};

export const DetailSection = ({
  title,
  children,
  actions,
  className,
}: DetailSectionProps) => (
  <div className={clsx("flex flex-col gap-2", className)}>
    <div className={clsx("flex justify-between items-center")}>
      <h3 className="font-base font-semibold text-gray-900">{title}</h3>
      {actions}
    </div>
    <dl className="flex flex-col divide-y divide-gray-200">{children}</dl>
  </div>
);

type DetailLineProps = {
  label?: ReactNode;
  value: ReactNode;
  labelClassName?: string;
  valueClassName?: string;
};

export const DetailLine = ({
  label,
  value,
  labelClassName,
}: DetailLineProps) => (
  <div className="flex py-2 justify-between items-center text-sm font-medium gap-10">
    {label && (
      <dt
        className={clsx(
          "leading-none flex h-auto items-center text-gray-500 text-xs",
          labelClassName
        )}
      >
        {label}
      </dt>
    )}
    <dd className="leading-none flex h-auto items-center text-gray-900 text-xs">
      {value}
    </dd>
  </div>
);

type DetailTableProps = {
  title: string;
  icon?: ReactNode;
  headers?: { col1: string; col2: string };
  rows: { col1: ReactNode; col2: ReactNode }[];
};

const DetailTable = ({ title, headers, rows, icon }: DetailTableProps) => (
  <div className="flex flex-col gap-y-2">
    <h3 className="font-medium text-gray-900">{title}</h3>
    <div className="overflow-y-auto">
      <table className="min-w-full divide-gray-200 divide-y">
        {headers && (
          <thead className="bg-gray-50">
            <TableHeader scope="col">{headers.col1}</TableHeader>
            <TableHeader scope="col">{headers.col2}</TableHeader>
          </thead>
        )}

        {rows.length > 0 && (
          <tbody className="bg-white divide-gray-200 divide-y">
            {rows.map((r, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <TableData className="text-gray-900">{r.col1}</TableData>
                <TableData className="flex justify-end text-gray-500">
                  {r.col2}
                </TableData>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {rows.length === 0 && (
        <EmptyStateContainer dataName={title} icon={icon} />
      )}
    </div>
  </div>
);

type TableHeaderProps = ThHTMLAttributes<{ className?: string }>;

const TableHeader = ({
  className,
  children,
  ...thProps
}: PropsWithChildren<TableHeaderProps>) => (
  <th
    {...thProps}
    className={clsx(
      "px-1 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase",
      className
    )}
  >
    {children}
  </th>
);

type TableDataProps = TdHTMLAttributes<{ className?: string }>;

const TableData = ({
  className,
  children,
  ...tdProps
}: PropsWithChildren<TableDataProps>) => (
  <td className={clsx("py-2 text-sm font-medium", className)} {...tdProps}>
    {children}
  </td>
);

DetailsAside.Section = DetailSection;
DetailsAside.Line = DetailLine;
DetailsAside.Table = DetailTable;
