import { getScrollbarStyle } from "@utils/styleStrings";
import { clsx } from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { Icon, IconType } from "../Icon";
import { CardDetails } from "./components/CardDetails";
import { CardDetailsRow, CardSize, HeightMode } from "./types";

type Props = {
  size?: CardSize;
  icon?: IconType;
  className?: string;
  isCollapsed?: boolean;
  childUpdated?: boolean;
  bodyClassName?: string;
  header: React.ReactNode;
  rows?: CardDetailsRow[];
  heightMode?: HeightMode;
  headerClassName?: string;
  minimizedHeight?: number;
  children?: React.ReactNode;
  headerOverlayColor?: string;
};

export const Card = ({
  rows,
  header,
  icon,
  children,
  className,
  size = "sm",
  bodyClassName,
  minimizedHeight,
  headerClassName,
  isCollapsed = false,
  headerOverlayColor = "",
  heightMode = HeightMode.Fit,
}: Props) => {
  const style = getStyle(size);
  const canToggleHeight = !!minimizedHeight;
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isMinimized, setIsMinimized] = useState(canToggleHeight);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);

  const checkOverflow = useCallback(() => {
    if (contentRef.current && minimizedHeight) {
      const contentHeight = contentRef.current.scrollHeight + 42;
      setIsContentOverflowing(contentHeight > minimizedHeight);
    }
  }, [minimizedHeight]);

  useEffect(() => {
    checkOverflow();
    if (contentRef.current) {
      const observer = new MutationObserver(checkOverflow);

      observer.observe(contentRef.current, {
        childList: true, // Watch for children being added/removed
        subtree: true, // Watch for changes deep inside the subtree
        characterData: true, // Watch for changes in text content
      });

      return () => observer.disconnect();
    }
  }, [checkOverflow]);

  const hasRows = rows && rows.length > 0;

  return (
    <div
      className={clsx("flex flex-col w-full shadow", style.parent, className)}
      style={{
        height:
          isMinimized && isContentOverflowing
            ? `${minimizedHeight}px`
            : heightMode === HeightMode.Full
            ? "100%"
            : "fit",
      }}
    >
      {/* HEADER */}
      <div
        className={clsx(
          "relative flex items-center justify-start gap-x-[8px]",
          "border border-gray-300 w-full overflow-hidden",
          "bg-gray-300 text-slate-800 leading-4 font-semibold",
          !isCollapsed && "rounded-b-none border-b-0",
          style.header,
          headerClassName
        )}
      >
        {headerOverlayColor && (
          <div
            className={clsx(
              `absolute top-0 left-0 w-full h-full opacity-10 pointer-events-none`,
              headerOverlayColor
            )}
          />
        )}
        {icon && (
          <Icon icon={icon} size={style.iconSize} color="text-slate-700" />
        )}
        {header}
      </div>

      {/* BODY */}
      {!isCollapsed && (
        <div
          ref={contentRef}
          className={clsx(
            "flex w-full flex-col overflow-x-auto overflow-y-hidden",
            "border border-gray-300 bg-slate-50",
            getScrollbarStyle("gray"),
            canToggleHeight && "relative overflow-hidden",
            heightMode,
            style.body,
            bodyClassName
          )}
        >
          {hasRows && (
            <CardDetails heightMode={heightMode} rows={rows} size={size} />
          )}

          {children && children}

          {/* Show More/Less Minimized Toggle Area */}
          {isContentOverflowing && canToggleHeight && (
            <div
              className={clsx(
                "absolute flex justify-center items-end h-[60px] w-full bottom-0 left-0 px-4",
                "bg-gradient-to-t from-slate-50 to-transparent hover:from-blue-100/60",
                "pb-[9px] font-semibold text-xs cursor-pointer hover:text-blue-900 text-slate-600"
              )}
              onClick={() => setIsMinimized(!isMinimized)}
            >
              {isMinimized ? "Show More" : "Show Less"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const getStyle = (size: CardSize) => {
  switch (size) {
    case "sm":
      return {
        parent: "rounded-lg",
        header: "rounded-lg text-md min-h-[40px] px-3",
        iconSize: 5,
        body: "rounded-b-lg",
      };
    default:
    case "md":
      return {
        parent: "rounded-xl",
        header: "rounded-y-xl text-lg min-h-[42px] px-3",
        iconSize: 5,
        body: "rounded-b-xl",
      };
    case "lg":
      return {
        parent: "rounded-2xl",
        header: "rounded-2xl text-xl min-h-[55px] px-4",
        iconSize: 5,
        body: "rounded-b-2xl",
      };
  }
};
