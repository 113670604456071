import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Spinner } from "../Loading";

type Props = {
  setActiveTab: (tabNum: number) => void;
  activeTab: number;
  options: ReactNode[];
  counts?: number[];
  height?: string;
  className?: string;
  titleClassName?: string;
  activeTabColor?: string;
  radius?: number;
  loading?: boolean;
};

export const OptionsToggleButton = ({
  counts,
  options,
  activeTab,
  className,
  titleClassName,
  activeTabColor = "bg-slate-700",
  height = "h-14",
  radius = 12,
  setActiveTab,
  loading = false,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [totalWidth, setTotalWidth] = useState<number>(400);
  const optionWidth = totalWidth / options.length;

  useEffect(() => {
    const handleResize = () =>
      setTotalWidth(containerRef.current?.offsetWidth || 400);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(
    () => setTotalWidth(containerRef.current?.offsetWidth || 400),
    [options]
  );

  const getBorderRadius = (firstTab: boolean, lastTab: boolean) => {
    if (firstTab) return `${radius}px 0 0 ${radius}px`;
    if (lastTab) return `0 ${radius}px ${radius}px 0`;
    return 0;
  };

  const renderGlareDecorations = (
    <div
      className={clsx(
        "absolute flex flex-col py-[6px] px-[12px] justify-between w-full top-0 h-full left-0 pointer-no-events opacity-[0.07] group-hover:opacity-[0.15]"
      )}
    >
      <div className="flex w-full gap-x-1">
        <div className="h-[5px] w-1/6 rounded-full bg-white" />
        <div className="h-[5px] w-[7px] rounded-full bg-white" />
      </div>
      <div className="flex gap-x-1 w-full justify-end">
        <div className="h-[5px] w-[7px] rounded-full bg-white" />
        <div className="h-[5px] w-[15%] rounded-full bg-white" />
      </div>
    </div>
  );

  return (
    <div
      className={clsx(
        "flex relative isolate items-center shadow-options-outer h-fit group",
        className
      )}
      ref={containerRef}
      style={{
        borderRadius: `${radius}px`,
      }}
    >
      <div
        className={clsx("flex relative w-full bg-white", height)}
        style={{
          borderRadius: `${radius}px`,
        }}
      >
        {/* Tab Details (Title && Count) */}
        {options.map((option, x) => (
          <div
            key={x}
            className={clsx(
              "absolute flex flex-center z-10",
              "gap-2 whitespace-nowrap border-r border-slate-200 h-full overflow-visible",
              "transition-colors duration-200 ease-in-out cursor-pointer",
              titleClassName,
              x === activeTab
                ? `text-white ${activeTabColor} shadow-selected-option`
                : "text-gray-800 bg-indigo-50/10 hover:bg-indigo-50",
              (x === options.length - 1 || x === activeTab) && "border-none"
            )}
            style={{
              width: `${optionWidth}px`,
              left: `${(x / options.length) * totalWidth}px`,
              borderRadius: getBorderRadius(x == 0, x == options.length - 1),
            }}
            onClick={() => setActiveTab(x)}
          >
            {option}
            {counts && (
              <div
                className={clsx(
                  x === activeTab
                    ? "bg-blue-50 text-gray-900"
                    : "bg-gray-600 text-blue-50",
                  "transition-colors duration-300 ease-in-out",
                  "py-0.5 px-2.5 rounded-full font-medium text-sm mt-[3px]"
                )}
              >
                {loading ? (
                  <Spinner
                    color={x === activeTab ? "text-gray-900" : "text-blue-50"}
                    size={4}
                  />
                ) : (
                  counts[x]
                )}
              </div>
            )}
          </div>
        ))}
        {/* Shadow */}
        <div
          className={clsx(
            "absolute flex top-[0] h-[full] left-0 z-20 shadow-options-inner pointer-events-none",
            height
          )}
          style={{
            width: `${totalWidth}px`,
            borderRadius: `${radius}px`,
          }}
        />
        {/* Glare Decorations */}
        <div
          className={clsx(
            "absolute flex top-[0] h-[full] left-0 z-30 pointer-events-none",
            height
          )}
          style={{
            width: `${totalWidth}px`,
          }}
        >
          {renderGlareDecorations}
        </div>
      </div>
    </div>
  );
};
