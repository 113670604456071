import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import pluralize from "pluralize";
import { ReactNode, useEffect, useState } from "react";
import { Cell, Column, Row, usePagination, useTable } from "react-table";
import { Button } from "../Buttons/Button";
import { Spinner } from "../Loading/Spinner";
import { EmptyTableState } from "./EmptyTableState";
import { CONTEXT_MENU_ID } from "./constants";

type Pagination = {
  pageIndex: number;
  totalRows: number;
  pageSize: number;
  gotoPage: (page: number) => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
};

type Sortability = {
  desc: boolean;
  currSort: string;
  sortColumns: sortColumn[];
};

type sortColumn = {
  id: string;
  onChange: () => void;
};

type Props<D extends Record<string, unknown>> = {
  columns: Column<D>[];
  data: D[];
  dataName?: string;
  emptyIcon?: ReactNode;
  sortability?: Sortability;
  onRowClick?: (row: Row<D>) => void;
  onCellClick?: (row: Row<D>, cell: Cell<D>) => void;
  selectedId?: string | null;
  preventRowClick?: boolean;
  loading?: boolean;
  className?: string;
  pagination?: Pagination;
  pageSize?: number;
  padCells?: boolean;
  verticalDividers?: boolean;
};

export function ManualTable<D extends { id: string }>({
  columns,
  data,
  onRowClick,
  onCellClick,
  sortability,
  preventRowClick = false,
  emptyIcon,
  dataName,
  loading = false,
  pagination,
  padCells = true,
  pageSize = 10,
  className,
  verticalDividers = false,
}: Props<D>) {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
  } = useTable({ columns, data }, usePagination);

  const hasData = page.length > 0;

  useEffect(() => {
    setPageSize(pagination ? pagination.pageSize : pageSize);
  }, [pageSize, pagination, setPageSize]);

  const renderLoadingSpinner = (
    <tr>
      <td className="px-6 py-4 text-gray-500 text-sm" colSpan={columns.length}>
        <Spinner size={10} color="text-blue-600" />
      </td>
    </tr>
  );

  const onRowSelection = (row: Row<D>) => {
    onRowClick && setSelectedRow(row.original.id);
    onRowClick && onRowClick(row);
  };

  const onCellSelection = (row: Row<D>, cell: Cell<D>) => {
    onCellClick && onCellClick(row, cell);
  };

  const renderTableRows = page.map((row) => {
    prepareRow(row);
    const isRowSelected = row.original.id === selectedRow;
    const onRowClickHandler = onRowClick
      ? { onClick: () => onRowSelection(row) }
      : {};
    return (
      // eslint-disable-next-line react/jsx-key
      <tr
        {...row.getRowProps()}
        className={clsx(
          onRowClick ? "cursor-pointer" : "cursor-default",
          isRowSelected ? "bg-blue-100" : "hover:bg-gray-50",
          verticalDividers && "divide-gray-200 divide-x"
        )}
        {...onRowClickHandler}
      >
        {row.cells.map((cell) => {
          const cellToRender =
            cell.column.id === CONTEXT_MENU_ID ? (
              <div className="flex justify-center">{cell.render("Cell")}</div>
            ) : (
              cell.render("Cell")
            );

          return (
            // eslint-disable-next-line react/jsx-key
            <td
              {...cell.getCellProps()}
              className={clsx(
                cell.column.id === CONTEXT_MENU_ID || !padCells
                  ? "px-2 py-0"
                  : "px-3 py-3",
                "text-gray-500 text-sm"
              )}
              onClick={(e) => {
                preventRowClick && e.stopPropagation(); // Prevent the row's onClick from firing
                onCellSelection(row, cell);
              }}
            >
              {cellToRender}
            </td>
          );
        })}
      </tr>
    );
  });

  return (
    <div className={clsx("w-full h-fit rounded-lg shadow-sm")}>
      <div
        className={clsx(
          "bg-white border-gray-200 overflow-x-auto border rounded-lg",
          hasData && pagination && "border-b-0 rounded-b-none",
          getScrollbarStyle("gray"),
          className
        )}
      >
        <table
          className="min-w-full divide-gray-200 divide-y"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={clsx(verticalDividers && "divide-gray-200 divide-x")}
              >
                {headerGroup.headers.map((column, index) => {
                  const sortable = sortability?.sortColumns.find(
                    (col) => col.id === column.id
                  )?.onChange;
                  const onSort = sortable ? { onClick: sortable } : {};

                  return (
                    // eslint-disable-next-line react/jsx-key
                    <th
                      scope="col"
                      className={clsx(
                        column.id === CONTEXT_MENU_ID
                          ? "w-16"
                          : "w-auto px-3 py-3",
                        "text-gray-500 text-xs font-medium tracking-wider uppercase bg-gray-50",
                        index === 0 && "rounded-tl-md",
                        index === columns.length - 1 && "rounded-tr-md",
                        column.width
                      )}
                      {...column.getHeaderProps()} //key is in here.
                    >
                      <div className="flex w-full" {...onSort}>
                        <div className="flex w-full">
                          {column.render("Header")}

                          {column.id === sortability?.currSort ? (
                            sortability?.desc ? (
                              <ArrowDownIcon className="ml-2 h-4 w-4" />
                            ) : (
                              <ArrowUpIcon className="ml-2 h-4 w-4" />
                            )
                          ) : null}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          {hasData && (
            <tbody
              className="w-full bg-white divide-gray-200 divide-y"
              {...getTableBodyProps()}
              data-test="table"
            >
              {loading ? renderLoadingSpinner : renderTableRows}
            </tbody>
          )}
        </table>

        {!hasData && (
          <EmptyTableState
            dataName={dataName}
            emptyIcon={emptyIcon}
            loading={loading}
          />
        )}
      </div>

      {hasData && pagination && (
        <TablePagination
          pageIndex={pagination.pageIndex}
          totalRows={pagination.totalRows}
          pageSize={pagination.pageSize}
          gotoPage={pagination.gotoPage}
          canPreviousPage={pagination.canPreviousPage}
          canNextPage={pagination.canNextPage}
        />
      )}
    </div>
  );
}

type TablePaginationProps = {
  totalRows: number;
  pageIndex: number;
  pageSize: number;
  gotoPage: (pageIndex: number) => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
};

export function TablePagination({
  totalRows,
  pageIndex,
  gotoPage,
  pageSize,
  canPreviousPage,
  canNextPage,
}: TablePaginationProps) {
  const pageCount = Math.ceil(totalRows / pageSize);
  return (
    <div
      className={clsx(
        "bg-white px-4 py-3 flex items-center rounded-b-lg justify-between border-gray-200 sm:px-6 border"
      )}
    >
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          disabled={!canPreviousPage}
          onClick={() => gotoPage(pageIndex - 1)}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() => gotoPage(pageIndex + 1)}
          disabled={!canNextPage}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Page <span className="font-medium">{pageIndex + 1}</span> of{" "}
            <span className="font-medium">
              {pageCount === 0 ? 1 : pageCount}
            </span>
            <span className="text-gray-500 ml-3">
              ({pluralize("result", totalRows, true)})
            </span>
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md space-x-3"
            aria-label="Pagination"
          >
            <Button
              theme="tertiary"
              disabled={!canPreviousPage}
              onClick={() => gotoPage(0)}
            >
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
            <Button
              theme="tertiary"
              disabled={!canPreviousPage}
              onClick={() => gotoPage(pageIndex - 1)}
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              Previous
            </Button>
            <Button
              theme="tertiary"
              onClick={() => gotoPage(pageIndex + 1)}
              disabled={!canNextPage}
            >
              Next
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
            <Button
              theme="tertiary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          </nav>
        </div>
      </div>
    </div>
  );
}
