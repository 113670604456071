import clsx from "clsx";
import { ITooltip } from "react-tooltip";

export type ScrollbarTheme = "gray" | "blue" | "dark" | "page" | "barOnly";

export const getScrollbarStyleFromType = (theme: ScrollbarTheme = "blue") => {
  switch (theme) {
    case "gray":
      return "scrollbar-thumb-slate-300 scrollbar-track-slate-50";
    case "page":
      return "scrollbar-thumb-slate-600 scrollbar-track-gray-200";
    case "dark":
      return "scrollbar-thumb-slate-900 scrollbar-track-slate-700";
    case "barOnly":
      return "scrollbar-thumb-slate-400 scrollbar-track-white";
    case "blue":
    default:
      return "scrollbar-thumb-blue-500 scrollbar-track-slate-100";
  }
};

export const getScrollbarStyle = (type?: ScrollbarTheme): string =>
  "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full " +
  getScrollbarStyleFromType(type);

export const getInputStyle = {
  borderRadius: "0.375rem",
  borderColor: "#D1D5DB",
  width: "100%",
  height: "38px",
  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
};

export const getShortInputStyle = {
  ...getInputStyle,
  height: "32px",
};

export const getSize = (num: number): string => {
  const validTailwindSizes = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 44,
    48, 52, 56, 60, 64, 72, 80, 96,
  ];
  const closestSize = validTailwindSizes.reduce((prev, curr) =>
    curr <= num ? curr : prev
  );
  return `h-${closestSize} w-${closestSize}`;
};

export type OptionsArrayContainerItemTheme = "edit" | "add" | "remove";

export const getOptionsArrayContainerItemFromType = (
  type: OptionsArrayContainerItemTheme = "edit"
) => {
  switch (type) {
    case "edit":
      return "";
    case "add":
      return "cursor-pointer hover:bg-blue-50 hover:border-blue-400 gap-x-2";
    case "remove":
    default:
      return "cursor-pointer hover:bg-rose-50 hover:border-rose-400 gap-x-2";
  }
};

export const optionsArrayContainerItemStyle = (
  type: OptionsArrayContainerItemTheme
): string =>
  "bg-white flex w-fit h-8 px-2 whitespace-nowrap items-center border border-slate-300 rounded-md text-slate-700 font-medium justify-center " +
  getOptionsArrayContainerItemFromType(type);

/**
 * Uses the relative luminance formula to determine the appropriate text color.
 * It is opinionated to prefer white text more often: The threshold for black
 * text is 0.8 instead of a more standard 0.5.
 *
 * @param bgHexColor accepts a full hexadecimal color code (ex: #E20074)
 * - is case-insensitive;
 * - "#" is optional.
 * - short (3 character) codes not supported!
 * @returns a Tailwind text color class for black(ish) or white
 */
export const getContrastingTextColor = (bgHexColor: string): string => {
  const hexColorRegex = /^#?([a-fA-F0-9]{6})$/;

  if (!hexColorRegex.test(bgHexColor)) {
    console.warn(`Invalid hexadecimal color code: ${bgHexColor}`);
    return "text-white";
  }

  const hex = bgHexColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // https://www.w3.org/TR/AERT/#color-contrast
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.8 ? "text-gray-800" : "text-white";
};

export const tableTitleTextStyles =
  "text-gray-500 text-xs font-medium tracking-wider uppercase";
export const nonTableTitleTextStyles =
  "normal-case text-sm font-medium tracking-normal";

export const darkButtonStyles =
  "text-sm font-medium text-white shadow-button-inner shadow-button-outer";
export const lightButtonStyles =
  "text-sm font-medium text-white shadow-button-inner-light shadow-button-outer";

export const sidebarTooltipStyles: ITooltip | undefined = {
  opacity: 0.95,
  place: "bottom-start",
  offset: 6,
  style: {
    color: "#0f172a",
    padding: "6px 14px",
    backgroundColor: "white",
  },
};
export const tableHeaderStyle = (center = true, cursorPointer = true) =>
  clsx(
    "absolute flex top-0 left-0 w-full !h-full leading-none",
    cursorPointer && "cursor-pointer",
    center ? "justify-center items-center text-center" : "text-left"
  );

export const tableCellStyle = (center = true) =>
  clsx(
    "flex w-full h-full leading-none",
    center && "justify-center items-center text-center"
  );
